import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/login', component: () => import(`@/pages/main/login`)},
        {path: '/logout', component: () => import(`@/pages/main/logout`)},
        {
            path: '/main/password', component: () => import(`@/pages/main/layout_blank`),
            children: [
                {path: '', component: () => import(`@/pages/main/password`)},
            ]
        },
        {
            path: '/main', component: () => import(`@/pages/main/layout_admin`),
            children: [
                {path: '', redirect: '/main/dashboard'},
                {path: 'dashboard', component: () => import(`@/pages/dashboard`)},

                {path: 'expert/expert', component: () => import(`@/pages/expert/expert`)},
                {path: 'expert/form', component: () => import(`@/pages/expert/form`)},
                {path: 'expert/form/:id', component: () => import(`@/pages/expert/form`)},

                {path: 'choose/choose', component: () => import(`@/pages/choose/choose`)},
                {path: 'choose/chlog', component: () => import(`@/pages/choose/chlog`)},
                {path: 'choose/chlog/:id', component: () => import(`@/pages/choose/chlogView`)},

                {path: 'statistics/project', component: () => import(`@/pages/statistics/project`)},
                {path: 'statistics/expert', component: () => import(`@/pages/statistics/expert`)},
                {path: 'statistics/workload', component: () => import(`@/pages/statistics/workload`)},
                {path: 'statistics/performance', component: () => import(`@/pages/statistics/performance`)},

                {path: 'settings/user', component: () => import(`@/pages/settings/user`)},
                {path: 'settings/dict', component: () => import(`@/pages/settings/dict`)},

                {path: 'oplog', component: () => import(`@/pages/oplog`)},
                {path: 'help', component: () => import(`@/pages/help`)},

                {path: '*', component: () => import(`@/pages/main/notFound`)}
            ]
        },
        {path: '*', component: () => import(`@/pages/main/notFound`)}
    ]
})

export default router
