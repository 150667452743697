<template>
    <el-select :value="value" value-key="id" multiple :collapse-tags="collapse" filterable @input="$emit('input', $event)" placeholder="选择专家"
        :size="size" style="width:100%" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item" >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.department }}</span>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'selectExpert',
    props: {
        value: {
            type: [Array],
            default: () => []
        },
        size: {
            default: 'small'
        },
        collapse: {
            default: true
        }
    },
    data() {
        return {
            options: []
        }
    },
    methods: {
        async getData() {
            const resp = await this.$http.get('/expert?page=1&pageSize=5000')
            this.options = resp.data.rows
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped></style>
