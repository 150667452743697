import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: {},
        app: {
            version: '',
        },
    },
    mutations: {
        set_user(state, user) {
            state.user = user
        },
        set_token (state, data) {
            localStorage.setItem('token', data)
            state.token = data
        }
    },
    getters: {
        user: state => {
            return state.user
        }
    }
})

export default store
