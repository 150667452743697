import Vue from 'vue';
import VueClipBoard from 'vue-clipboard2'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css' // 响应式
import http from "./http"
import './prototype'
import './echarts'
import '../components'
import Print from 'vue-print-nb'
Vue.use(Print)

Vue.use(VueClipBoard)
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$http = http
