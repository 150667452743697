import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import VuePrintNB from 'vue-print-nb'
import './plugins'
import './mock'

Vue.use(VuePrintNB)

Vue.config.productionTip = false

Vue.filter('formatDate', function(value) {
  let date = new Date(value)
  return date.format('yyyy-MM-dd')
});

Vue.filter('formatDateTime', function(value) {
  let date = new Date(value)
  return date.format('yyyy-MM-dd hh:mm:ss')
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
