import Mock from 'mockjs'

const dastboard = {
    id: '10',
    label: '系统首页',
    path: '/main/dashboard',
    icon: 'el-icon-view',
}

const choose = {
    id: '20',
    label: '专家抽取',
    path: '/main/choose/choose',
    icon: 'el-icon-aim',
}

const chooseHistory = {
    id: '30',
    label: '抽取记录',
    path: '/main/choose/chlog',
    icon: 'el-icon-brush',
}

const experts = {
    id: '40',
    label: '专家库',
    path: '/main/expert/expert',
    icon: 'el-icon-user',
}

const statistics = {
    id: '60',
    label: '统计报表',
    path: '/main/statistics',
    icon: 'el-icon-pie-chart',
    children: [
        // {
        //     id: '60-1',
        //     label: '项目统计',
        //     path: '/main/statistics/project',
        // },
        {
            id: '60-2',
            label: '专家统计',
            path: '/main/statistics/expert',
        },
        {
            id: '60-3',
            label: '工作量',
            path: '/main/statistics/workload',
        },
        {
            id: '60-4',
            label: '效率效益',
            path: '/main/statistics/performance',
        },
    ]
}

const settings = {
    id: '100',
    label: '系统设置',
    path: '/main/settings',
    icon: 'el-icon-setting',
    children: [
        {
            id: '100-1',
            label: '用户管理',
            path: '/main/settings/user',
        },
        {
            id: '100-2',
            label: '字典设置',
            path: '/main/settings/dict',
        },
    ]
}

const oplog = {
	id: '999',
	label: '操作日志',
	path: '/main/oplog',
	icon: 'el-icon-notebook-1',
}

const help = {
	id: '1000',
	label: '使用说明',
	path: '/main/help',
	icon: 'el-icon-help',
}


let menu = []
menu.push(dastboard)
menu.push(choose)
menu.push(chooseHistory)
menu.push(experts)
menu.push(statistics)
menu.push(settings)
menu.push(oplog)
menu.push(help)

Mock.mock('/api/menu', () => {
    return menu
})