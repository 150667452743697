import Mock from '../utils'
const Random = Mock.Random

let types = ['未开始', '进行中', '已结束']

var selectList = [
    { label: "医疗技术服务", value: "" },
    { label: "装修工程", value: "" },
    { label: "软件开发", value: "" },
    { label: "打印出版", value: "" },
]

const makeProject = () => {
    let res = []
    for (let i = 1; i < 151; i++) {
        res.push({
            id: i,
            type: Random.pick(['医疗技术服务', '装修工程', '软件开发', '打印出版']),
            num: '项目编号' + i,
            name: '项目名称' + i,
            address: "",
            user: Random.pick(['联系人1', '联系人2', '联系人3']),
            created: Random.fdate(-14, -7) + ' ' + Random.ftime(),
            latest: Random.fdate() + ' ' + Random.ftime(),
            time: Random.fdate(-7, 0) + ' ' + Random.ftime(),
            status: Random.pick(types),
        })
    }
    return res
}

let data = makeProject()

// eslint-disable-next-line no-unused-vars
Mock.api('/api/project/types', 'get', (req, res) => {
    return types
})

// eslint-disable-next-line no-unused-vars
Mock.api('/api/project/select', 'get', (req, res) => {
    return selectList
})

// eslint-disable-next-line no-unused-vars
Mock.api('/api/project/table', 'get', (req, res) => {
    let qs = data
    var sh = req.query
    qs = qs.filter(item => {
        let name = item.name.includes(sh.name)
        let num = item.num.includes(sh.num)
        let status = item.status.includes(sh.status)
        if (!sh.name) {
            name = true
        }
        if (!sh.num) {
            num = true
        }
        if (!sh.status) {
            status = true
        }
        return name && status && num
    })
    return {
        total: qs.length,
        data: Mock.pagination(qs, req.query.page || 1, req.query.pageSize || 20),
    }
})

Mock.api('/api/project/tree', 'get', (req, res) => {
    // 定义一个对象数组
    let qs = data.filter(e => e.status=='未开始')
    // 使用 reduce() 方法对对象数组进行分组
    let group = qs.reduce((result, item) => {
        // 查找是否已经存在该类型的分组
        let group = result.find(group => group.name === item.type);
        // 如果不存在，就创建一个新的分组
        if (!group) {
            group = { name: item.type, children: [] };
            result.push(group);
        }
        // 将该元素添加到对应的分组中
        group.children.push(item);
        return result;
    }, []);

    // 将分组后的结果转换为树状结构
    let tree = group.map(group => {
        return { name: group.name, children: group.children };
    });

    return tree

})

// eslint-disable-next-line no-unused-vars
Mock.api('/api/project/form', 'post', (req, res) => {
    let form = JSON.parse(req.body)
    form.id = new Date().getTime()
    form.type = "未开始"
    form.created = new Date().format('yyyy-MM-dd hh:mm:ss')
    form.latest = Random.fdate() + ' ' + Random.ftime()
    data.unshift(form)
    return {
        type: 'success',
        msg: '保存成功'
    }
})


// 模拟上传图片接口
Mock.mock('/api/project/upload', 'post', function (options) {
    // eslint-disable-next-line no-unused-vars
    const file = options.body.get('file') // 获取上传的文件对象
    // 模拟上传成功后返回的数据
    return {
        code: 200,
        message: '上传成功',
        data: {
            url: Random.image('200x100', '#4A7BF7', 'Mock.js')
        }
    }
})
