import Mock from '../utils'
const Random = Mock.Random
Mock.api('/api/settings/dict', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: 0,
            records: []
        }
    }
})

var selectData = {
    xlList: [
        {
            label: '博士',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '硕士',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '本科',
            value: Math.floor(Math.random() * 1000)
        },
    ],
    zcList: [
        {
            label: '主任医师',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '副主任医师',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '高级工程师',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '工程师',
            value: Math.floor(Math.random() * 1000)
        },
    ],
    zyList: [
        {
            label: '临床医学专业',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '精神医学专业',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '麻醉学专业',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '医学影像学专业',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '放射医学专业',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '眼视光医学专业',
            value: Math.floor(Math.random() * 1000)
        },
        {
            label: '妇幼保健医学专业',
            value: Math.floor(Math.random() * 1000)
        }, {
            label: '精神医学',
            value: Math.floor(Math.random() * 1000)
        }, {
            label: '口腔医学专业',
            value: Math.floor(Math.random() * 1000)
        },
    ],
    ksList:[
        {
            label: 'CT',
            value: ''
        },  {
            label: 'MR',
            value: ''
        },  {
            label: '骨科',
            value: ''
        },  {
            label: '皮肤科',
            value: ''
        },  {
            label: '眼科',
            value: ''
        },
    ],
    tpList:[
        {
            label: '医技',
            value:  Math.floor(Math.random() * 1000)
        },  {
            label: '外科',
            value: Math.floor(Math.random() * 1000)
        },  {
            label: '内科',
            value: Math.floor(Math.random() * 1000)
        },  {
            label: '维修',
            value: Math.floor(Math.random() * 1000)
        },  {
            label: '后勤',
            value: Math.floor(Math.random() * 1000)
        },{
            label: '总支',
            value: Math.floor(Math.random() * 1000)
        },
    ]
}

// eslint-disable-next-line no-unused-vars
Mock.api('/api/settings/select', 'get', (req, res) => {
    return selectData
})


const makeProject = () => {
    let res = []
    for (let i = 1; i < 101; i++) {
        res.push({
            id: i.toString(),
            name: Random.cname(),
        })
    }
    return res
}

let data = makeProject()


// eslint-disable-next-line no-unused-vars
Mock.api('/api/settings/bar', 'get', (req, res) => {
    let qs = data
    var xdata  =[]
    var ydata = []
    for (var i=0;i<15;i++)
    {
        let index = Math.floor(Math.random() * qs.length);
        xdata.push(qs[index].name);
        ydata.push(Math.floor(Math.random() * 1000))
    }
    return {
        xdata: xdata,
        ydata: ydata
    }
})
