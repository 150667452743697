<template>
    <el-card shadow="hover" :body-style="{ padding: '0 20px' }">
        <div slot="header" class="clearfix">
            <span>项目甘特图</span>
        </div>
        <div style="width:100%">
            <v-chart autoresize :option="option" style="height: 390px" v-loading="loading"></v-chart>
        </div>
    </el-card>
</template>

<script>
export default {
    name: 'ganttChart',
    data() {
        let projectItem = ['项目启动', '任务一', '任务二', '任务三', '撰写总报告'],
            projectItemStart = ['2023-04-01', '2023-04-03', '2023-04-10', '2023-04-16', '2023-04-19'],
            projectItemEnd = ['2023-04-02', '2023-04-12', '2023-04-16', '2023-04-19', '2023-04-20'];
        let projectItemStartValue = projectItemStart.map((item) => {
            return new Date(item).valueOf()
        })
        let projectItemDuringValue = projectItemEnd.map((item, i) => {
            return new Date(item).valueOf() - projectItemStartValue[i]
        })
        let dateMin = projectItemStartValue.sort()[0];
        return {
            loading: false,
            option: {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        var tar = params[1];
                        return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value / (1000 * 60 * 60 * 24) + '天';
                    }
                },
                grid: {
                    top: '3%',
                    left: '3%',
                    right: '4%',
                    bottom: '5%',
                    containLabel: true
                },
                yAxis: {
                    type: 'category',
                    splitLine: {
                        show: false
                    },
                    data: projectItem
                },
                xAxis: {
                    type: 'value',
                    min: dateMin,
                    axisLabel: {
                        color: '#333', // 坐标轴文字颜色
                        formatter: function (param) {
                            console.log(param)
                            let date = new Date(param);
                            let dateLabel = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                            return dateLabel;
                        }
                    }
                },
                series: [{
                    name: 'Placeholder',
                    type: 'bar',
                    stack: 'Total',
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent'
                    },
                    emphasis: {
                        itemStyle: {
                            borderColor: 'transparent',
                            color: 'transparent'
                        }
                    },
                    data: projectItemStartValue
                },
                {
                    name: '周期',
                    type: 'bar',
                    stack: 'Total',
                    data: projectItemDuringValue
                }
                ]
            }
        }
    }
}
</script>
